import React from 'react'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { TEXT_STYLES } from 'TEXT_STYLES'

import { Button, ButtonType, TextInput } from '@junglescout/edna'
import { FieldGroup } from 'ui_elements/FieldGroup/FieldGroup'

import { flagDataTypes } from 'types/general'

import { MWS_SYNC_DATA_ATTRIBUTE_IDS } from 'constants/data_attributes/account/mwsSync'
import { AMAZON_MWS_PPC_SYNC_STEPS, MWS_SYNC_STATUS } from 'constants/account'
import { sendSegmentMWSStepEvent } from 'helpers/segment'
import { redirectToSellerCentralConsent } from 'helpers/amazon_seller_account'

import { AbstractModal } from './Abstract'
import { AccountSyncError } from './AccountSyncError/AccountSyncError'
import { AccountSyncSuccess } from './AccountSyncSuccess/AccountSyncSuccess'

const CenterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const LWAWrapper = styled.div`
  margin: 20px 0;
  padding: 15px;
  max-height: 60vh;
  overflow: scroll;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
  a {
    ${TEXT_STYLES.ButtonOrange}
    color: ${props => props.theme.colors.primary}
  }
`
const LWAIcon = styled.img`
  width: 230px;
  height: 115px;
`
const LWAButton = styled(Button)`
  margin: 33px 0 10px 0;
`
const LWAMessage = styled.div`
  ${TEXT_STYLES.BodyBlack}
  color: ${props => props.theme.colors.grey700};
  font-size: 16px !important;
  text-align: center;
  max-width: 258px;
  margin: 15px auto;
`
const P = styled.p`
  ${TEXT_STYLES.H4Black}
  margin: 0;
  margin-bottom: 20px;
`

class LoginWithAmazonStep extends AbstractModal {
  state = {
    accountName: this.props.data.label,
    sellerEmail: this.props.data.sc_email,
    errors: {
      accountName: null,
      sellerEmail: null
    }
  }

  componentDidUpdate() {
    if (this.props.createSuccess) {
      super.onNextClick()
    }
  }

  onNextClick() {
    const {
      isUpgrade,
      data: {
        auth_token,
        spapi_oauth_code,
        label,
        merchant_id,
        primary_marketplace_id,
        sc_email,
        region
      },
      mwsPpcSyncCreateNewAccount
    } = this.props

    const accountData = {
      auth_token,
      spapi_oauth_code,
      label,
      merchant_id,
      primary_marketplace_id,
      sc_email,
      region
    }

    mwsPpcSyncCreateNewAccount(accountData, isUpgrade)
  }

  onAccountNameChange(event) {
    const accountName = event.target.value
    this.setState({ accountName })
    this.props.mwsSyncSetAccountName(accountName)
    this.validateInput(accountName, 'accountName')
  }

  onAccountNameBlur(event) {
    this.validateInput(event.target.value?.trim(), 'accountName')
  }

  onEmailChange(event) {
    const sellerEmail = event.target.value?.trim()
    this.setState({ sellerEmail })
    this.props.mwsSyncSetSellerEmail(sellerEmail)
    this.validateSellerEmailInput(sellerEmail)
  }

  onEmailBlur(event) {
    this.validateSellerEmailInput(event.target.value?.trim())
  }

  onLWAButtonClick() {
    const {
      data: { label, primary_marketplace_id, region, sc_email },
      availableMarketplaces
    } = this.props

    sendSegmentMWSStepEvent(
      'Connection started',
      region,
      availableMarketplaces,
      primary_marketplace_id,
      label,
      sc_email
    )

    redirectToSellerCentralConsent({
      region,
      amazonMarketplaceId: primary_marketplace_id,
      label,
      email: sc_email
    })
  }

  static renderHelpLink(fromSetupPage, t) {
    const dataId = fromSetupPage
      ? MWS_SYNC_DATA_ATTRIBUTE_IDS.Buttons.MwsModal
          .SetupWizardHavingTroubleArticle
      : MWS_SYNC_DATA_ATTRIBUTE_IDS.Buttons.MwsModal.HavingTroubleArticle
    return (
      <a
        href="https://support.junglescout.com/hc/en-us/articles/360021110574-Adding-and-Syncing-your-Amazon-Seller-Central-Jungle-Scout-Accounts"
        rel="noopener noreferrer"
        target="_blank"
        data-id={dataId}>
        {t('mwsSync:AccountSync.havingTrouble', 'Having Trouble?')}
      </a>
    )
  }

  renderInitialScreen = () => {
    const { t, fromSetupPage } = this.props
    const {
      accountName,
      sellerEmail,
      errors: { accountName: accountNameError, sellerEmail: sellerEmailError }
    } = this.state

    const fieldAccountName = t(
      'mwsSync:AccountSync.fieldAccountName',
      'Account Nickname'
    )
    const fieldAccountNamePlaceholder = t(
      'mwsSync:AccountSync.fieldAccountNamePlaceholder',
      'Name your account (example: “Awesome Co.”)'
    )
    const fieldSellerCentralEmail = t(
      'mwsSync:AccountSync.fieldSellerCentralEmail',
      'Seller Central Email'
    )
    const fieldSellerCentralEmailPlaceholder = t(
      'mwsSync:AccountSync.fieldSellerCentralEmailPlaceholder',
      'seller@gmail.com'
    )

    const loginButtonDataId = fromSetupPage
      ? MWS_SYNC_DATA_ATTRIBUTE_IDS.Buttons.MwsModal
          .SetupWizardLogInToSellerCentral
      : MWS_SYNC_DATA_ATTRIBUTE_IDS.Buttons.MwsModal.LogInToSellerCentral

    return (
      <LWAWrapper>
        {!fromSetupPage && (
          <>
            <P>
              {t(
                'mwsSync:AccountSync.lwa.cardTitle',
                'Seller Central Account Sync'
              )}
            </P>
            <CenterWrapper>
              <LWAIcon
                src="https://djga94vwgu7gt.cloudfront.net/assets/junglescout/mws-lwa-login@3x.png"
                alt="login with amazon icon"
              />
              <LWAMessage>
                {t(
                  'mwsSync:AccountSync.lwa.accountSyncMessage',
                  'Login to Amazon to sync your Seller Central account.'
                )}
              </LWAMessage>
            </CenterWrapper>
          </>
        )}
        <FieldGroup>
          <TextInput
            label={fieldAccountName}
            error={accountNameError}
            placeholder={fieldAccountNamePlaceholder}
            value={accountName}
            onChange={event => this.onAccountNameChange(event)}
            onBlur={event => this.onAccountNameBlur(event)}
          />
        </FieldGroup>
        <FieldGroup style={{ marginBottom: '0' }}>
          <TextInput
            error={sellerEmailError}
            id="sellerCentralEmail"
            label={fieldSellerCentralEmail}
            placeholder={fieldSellerCentralEmailPlaceholder}
            type="email"
            value={sellerEmail}
            onChange={event => this.onEmailChange(event)}
            onBlur={event => this.onEmailBlur(event)}
          />
        </FieldGroup>
        <CenterWrapper>
          <LWAButton
            btnType={ButtonType.PRIMARY}
            onClick={() => this.onLWAButtonClick()}
            disabled={!(this.props.data.label && this.props.data.sc_email)}
            dataId={loginButtonDataId}>
            {t(
              'mwsSync:AccountSync.lwa.authenticateAccountButton',
              'Authenticate Account Connection'
            )}
          </LWAButton>
          {LoginWithAmazonStep.renderHelpLink(fromSetupPage, t)}
        </CenterWrapper>
      </LWAWrapper>
    )
  }

  renderContent() {
    const { mwsSyncStatus, errors } = this.props

    switch (mwsSyncStatus) {
      case MWS_SYNC_STATUS.initial:
        return this.renderInitialScreen()
      case MWS_SYNC_STATUS.success:
        return <AccountSyncSuccess errors={errors} type="mws" />
      case MWS_SYNC_STATUS.failure:
        return (
          <AccountSyncError
            type="mws"
            handleButtonClick={() => this.onLWAButtonClick()}
          />
        )
      default:
        return null
    }
  }

  validateInput(value, prop, errorMessage = this.emptyFieldError) {
    const errors = this.state.errors || {}

    if (!value) {
      errors[prop] = errorMessage
    } else if (errors[prop]) {
      delete errors[prop]
    }

    this.setState({ errors: { ...errors } })
  }

  validateSellerEmailInput(value) {
    const fieldSellerCentralEmailError = this.props.t(
      'mwsSync:AccountSync.fieldSellerCentralEmailError',
      'Field is required. *Use registered Seller Central Account email.'
    )

    this.validateInput(value, 'sellerEmail', fieldSellerCentralEmailError)
  }

  get emptyFieldError() {
    return this.props.t(
      'mwsSync:AccountSync.emptyFieldError',
      'Field is required.'
    )
  }

  get nextModal() {
    return AMAZON_MWS_PPC_SYNC_STEPS.ppcSync
  }

  get previousModal() {
    return AMAZON_MWS_PPC_SYNC_STEPS.selectRegion
  }

  get backButtonDisabled() {
    return this.props.isLoading
  }

  get hideNextButton() {
    return this.props.mwsSyncStatus !== MWS_SYNC_STATUS.success
  }

  get nextButtonLoading() {
    return this.props.isLoading
  }

  get activeStepIndex() {
    return 1
  }
}

LoginWithAmazonStep.defaultProps = {
  errors: [],
  isLoading: false,
  createSuccess: false,
  isUpgrade: false,
  previousModal: '',
  fromSetupPage: false,
  availableMarketplaces: undefined
}

LoginWithAmazonStep.propTypes = {
  previousModal: PropTypes.string,
  showMwsSyncModal: PropTypes.func.isRequired,
  mwsSyncStatus: PropTypes.string.isRequired,
  isUpgrade: PropTypes.bool,
  data: PropTypes.shape({
    label: PropTypes.string,
    primary_marketplace_id: PropTypes.string,
    region: PropTypes.string,
    sc_email: PropTypes.string
  }).isRequired,
  errors: PropTypes.arrayOf(PropTypes.string),
  createSuccess: PropTypes.bool,
  isLoading: PropTypes.bool,
  mwsPpcSyncCreateNewAccount: PropTypes.func.isRequired,
  mwsSyncSetAccountName: PropTypes.func.isRequired,
  mwsSyncSetSellerEmail: PropTypes.func.isRequired,
  flagData: flagDataTypes.isRequired,
  fromSetupPage: PropTypes.bool,
  availableMarketplaces: PropTypes.objectOf(PropTypes.any)
}

export default withTranslation(['mwsSync'])(LoginWithAmazonStep)
